.risks {
    display: grid;
    gap: .2rem 1.5rem;
    padding-bottom: 1rem;
    grid-template-columns: auto minmax(50rem, 62.4rem) auto;
    align-items: center;
    justify-content: start;
}

.programs {
    display: flex;
    gap: 2rem;
    padding: 2.8rem .8rem;
    justify-content: space-around;
}