/*.App {*/
/*  display: flex;*/
/*  flex-flow: row nowrap;*/
/*  flex-grow: 1;*/
/*  text-align: center;*/
/*}*/

.App {
    flex-grow: 1;
    display: grid;
    grid-template-areas: 'app_menu  app_subm  app_name  app_user'
                       'app_menu  app_subm  app_cont  app_cont';
    grid-template-columns: 7.5rem minmax(max-content, auto) 1fr auto;
    grid-template-rows: var(--header-height) 1fr;
}