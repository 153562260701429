.main {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    min-height: 2rem;
    cursor: pointer !important;
}

* + .main {
    margin-top: 1.6rem;
}

.main > input {
    width: 20px;
    height: 20px;
    opacity: 0;
    cursor: pointer !important;
}
.main > span {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
.main > input + span:before {
    content: '';
    order: -1;
    margin-left: -20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #eee;
    transition: .2s;
}
.main > input + span:after {
    content: '';
    order: -1;
    margin: 4px 9px 4px -16px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
    box-shadow: -2px 2px 5px 0 rgb(0 0 0 / 29%);
    transition: .2s;
}
.main > input:checked + span:before {
    background: var(--primary-clr--active);
}
.main > input:checked + span:after {
    background: #fff;
}

.main.big > input {
    width: 30px;
    height: 30px;
}
.main.big > span {
    font-size: 24px;
    font-weight: bold;
}
.main.big > input + span:before {
    margin-left: -30px;
    width: 30px;
    height: 30px;
}
.main.big > input + span:after {
    margin: 6px 16px 6px -26px;
    width: 20px;
    height: 20px;
}

.main.toggle > input {
    width: 30px;
}
.main.toggle > input + span:before {
    margin-left: -30px;
    width: 30px;
    border-radius: 10px;
}
.main.toggle > input + span:after {
    margin: 4px 21px 4px -26px;
    background: #fff;
}
.main.toggle > input:checked + span:after {
    margin: 4px 11px 4px -16px;
}

.main.big.toggle > input {
    width: 50px;
}
.main.big.toggle > input + span:before {
    margin-left: -50px;
    width: 50px;
    border-radius: 15px;
}
.main.big.toggle > input + span:after {
    margin: 6px 38px 6px -48px;
    background: #fff;
}
.main.big.toggle > input:checked + span:after {
    margin: 6px 18px 6px -28px;
}