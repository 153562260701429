.overlay {
    overflow: auto;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.5);
}

.modal {
    overflow: auto;
    position: relative;
    max-height: 90%;
    width: 100%;
    max-width: 73rem;
    padding: 3rem;
    border-radius: .4rem;
    background: #fff;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
}

.modal.modal--large {
    max-width: 93.2rem;
}

.modal.modal--extra-large {
    max-width: 100.24rem;
}

.title {
    margin-bottom: 2rem;
    font: var(--main-title);
    color: var(--text-clr);
}

.close {
    position: absolute;
    right: 3rem;
    top: 3rem;
    width: 2rem;
    height: 2rem;
    font: 700 1.8rem/2rem 'Roboto', sans-serif;
    color: var(--primary-clr);
    cursor: pointer;
}

.modalGrid {
    display: grid;
    grid-gap: 3rem 5.8rem;
    grid-template-columns: 1fr 1fr;
}

.modalButtons {
    margin-top: 4rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}
.modalButtons.alignLeft {
    justify-content: flex-start;
    column-gap: 2rem;
}