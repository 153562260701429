.inputWrap {
    display: flex;
    flex-flow: column-reverse;
}

.inputWrap > input, .input {
    height: 3rem;
    padding: .8rem;
    border: 1px solid var(--border-clr);
    border-radius: 4px;

    font: 400 1.2rem/1.4rem 'Roboto', sans-serif;
    color: var(--text-clr);
}

.small {
    max-width: 7.6rem;
}

.inputWrap > textarea {
    height: 5rem;
    padding: .8rem;
    border: 1px solid var(--border-clr);
    border-radius: 4px;

    font: 400 1.2rem/1.4rem 'Roboto', sans-serif;
    color: var(--text-clr);
}

.inputWrap > textarea.big {
    height: 14.3rem;
}

.label {
    margin-bottom: .6rem;
    font: 400 1rem/1rem 'Roboto', sans-serif;
    text-transform: uppercase;
    color: var(--text-clr--bright);
}

.inputWrap > *:required + .label:after {
    content: ' *';
    color: var(--decline-clr);
}

.inputWrap > *:disabled,
.inputWrap > *::placeholder {
    color: var(--text-clr--bright);
}

.inputWrap + .inputWrap {
    margin-top: 1.5rem;
}

.inlineInputs {
    display: flex;
    flex-flow: row nowrap;
    gap: 3rem;
}

.inlineInputs > * {
    width: 100%;
    margin: 0 !important;
}

.autocompleteWrapper {
    position: relative;
}

.autocompleteWrapper.autocompleteActive > input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.autocompleteWrapper > .autocompleteSuggestions {
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(100% - 1.6rem);
    display: flex;
    flex-flow: column nowrap;
    background-color: #ffffff;
    max-height: 10rem;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    list-style-type: none;
    border: 1px solid lightgrey;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.autocompleteWrapper > .autocompleteSuggestions > li {
    padding: 0.8rem;
    background-color: #ffffff;
}

.autocompleteWrapper > .autocompleteSuggestions > li.active {
    background-color: #efefef;
}