.table {
    width: 100%;
    border-collapse: collapse;
}
.table td {
    padding: .5rem 1rem;
    border: 1px solid var(--border-clr--light);
}
.table td:first-child { padding: .5rem 2.5rem; }
.table td:not(:first-child) { width: 8%; }
.table td:has(.buttonAdd) {
    padding: 0;
    border: none;
    background: rgba(83, 166, 150, .2);
}

.file {
    overflow: hidden;
    display: flex;
    align-items: center;
}
.fileIcon {
    width: 4.2rem;
    height: 4.2rem;
    background: rgba(255, 255, 255, .7) no-repeat center / contain;
}
.fileIcon.pdf { background-image: url('../../../public/i/icon-pdf.svg'); }
.fileName {
    padding-left: 1.1rem;
    font: 400 1rem/1.2rem 'Roboto', sans-serif;
    color: var(--text-clr);
}

.control {
    display: block;
    width: 2.2rem;
    height: 2rem;
    margin: 0 auto;
    background: no-repeat center;
}
.control:disabled {
    opacity: .5;
    filter: grayscale(1);
}
.control.del {
    background-image: url('../../../public/i/icon-del.svg');
    background-size: 1.6rem;
}
.control.manual {
    background-image: url('../../../public/i/icon-manual.svg');
}
.control.ai {
    background-image: url('../../../public/i/icon-ai.svg');
}

.buttonAdd {
    display: flex;
    width: 100%;
    height: 4rem;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    color: var(--primary-clr);
    font-weight: bold;
}
.buttonAdd:before {
    content: '';
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    background: url('../../../public/i/icon-add-small.svg') no-repeat center / contain;
}
.upload {
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
}