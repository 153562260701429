.wrap {
    position: relative;
    max-width: 27rem;
    min-height: 22rem;
    padding: 2rem 2rem 5.5rem;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 5px;
}

.titleWrap {
    display: flex;
    gap: 1.6rem;
    align-items: center;
}

.icon {
    width: 4rem;
    height: 4rem;
    border: 1px solid var(--primary-clr--translucent);
    border-radius: 50%;
    background: transparent no-repeat center;
}
.icon:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    mask: no-repeat center;
    background-color: var(--primary-clr);
}
.diabetes:after { mask-image: url('icon-diabetes.svg'); }
.pressure:after { mask-image: url('icon-pressure.svg'); }
.vision:after { mask-image: url('icon-vision.svg'); }
.stress:after { mask-image: url('icon-stress.svg'); }

.title {
    font: 700 2rem/2.3rem 'Roboto', sans-serif;
    color: var(--primary-clr);
}

.text {
    margin: 1.6rem 0;
    color: var(--primary-clr);
    font: 400 1.4rem/2rem 'Roboto', sans-serif;
}

.buttonWrap {
    position: absolute;
    bottom: 2rem;
}