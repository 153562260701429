.panel {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
}

.logoWrapper {
    width: 16.7rem;
    height: 3.4rem;
    margin-bottom: 3rem;
    background: url('./i/logo.svg');
}

.title {
    font: 400 2.5rem/2.9rem 'Roboto', sans-serif;
    margin-bottom: 1.5rem;
}

.subtitle {
    font: 400 1.3rem/1.7rem 'Roboto', sans-serif;
    margin-bottom: 3rem;
}

.socials {
    display: flex;
    align-items: center;
    gap: 2rem;
    list-style-type: none;
    margin-bottom: 3rem;
}

.socialsLink {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    background-size: cover;
    transition: all ease-in-out .3s;
}

.socialsLink:hover {
    filter: brightness(0);
}

.socialsLink.google {
    background: url('i/google.svg') no-repeat center;
}

.socialsLink.facebook {
    background: url('i/fb.svg') no-repeat center;
}

.socialsLink.linkedin {
    background: url('i/linked.svg') no-repeat center;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.navLink {
    display: block;
    font: 400 1.3rem/1.7rem 'Roboto', sans-serif;
    color: var(--text-clr);
    padding: 0 1.5rem;
    text-transform: uppercase;
    white-space: nowrap;
}

.navLink:hover {
    text-decoration: underline;
}

.nav > .navLink:first-child {
    padding-left: 0;
}

.nav > .navLink:not(:last-child) {
    border-right: 2px solid var(--border-clr--light);
}