.container {
    margin: 8rem var(--page-margin-h) 0;
}

.row {
    display: grid;
    grid-template-columns: minmax(auto, 35.5rem) minmax(auto, 48rem);
    justify-content: center;
    gap: 10rem;
}

.inputWrap + .inputWrap {
    margin-top: 1.5rem;
}

.inputWrap > input {
    padding-right: 3.5rem;
}

.inputWrapLogin,
.inputWrapPassword,
.inputWrapEmail {
    position: relative;
}

.inputWrap:after {
    content: '';
    position: absolute;
    display: block;
    right: 1rem;
}

.inputWrapLogin:after {
    bottom: 0.79rem;
    width: 1.42rem;
    height: 1.42rem;
    background: url('./i/person.svg') no-repeat center;
}

.inputWrapPassword:after {
    bottom: 0.7rem;
    width: 1.4rem;
    height: 1.6rem;
    background: url('./i/lock.svg') no-repeat center;
}

.inputWrapEmail:after {
    bottom: 0.75rem;
    width: 1.6rem;
    height: 1.5rem;
    background: url('./i/email.svg') no-repeat center;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 3rem;
}

.actions > * {
    margin: 0 auto;
}

.actions > * {
    max-width: 15.5rem;
    width: 100%;
}

.reset {
    margin-top: 2.5rem;
    margin-bottom: -2.5rem;
    text-align: center;
}

.resetLink {
    font: 400 1.2rem/1.4rem 'Roboto', sans-serif;
    color: var(--text-clr--bright);
    cursor: pointer;
    transition: color ease-in-out .3s;
}

.resetLink:hover {
    color: var(--text-clr--light);
}

.info {
    margin-top: 1.5rem;
    color: var(--text-clr--bright);
}

.button {
    min-width: 10rem;
    padding: .7rem 1rem;
    border: 1px solid var(--primary-clr);
    border-radius: 1.5rem;
    background: transparent;
    font: 700 1.2rem/1.4rem 'Roboto', sans-serif;
    color: var(--primary-clr);
    text-align: center;
    transition: background-color ease-in-out .3s;
}

.button:hover {
    background-color: var(--primary-bg--active);
}