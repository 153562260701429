.inputs { width: 70%; }

.plainInput {
    width: 100%;
    height: 3.3rem;
    padding: .5rem 1.3rem;
    background: #F7F9FC;
    font: 400 1.3rem/3.3rem 'Roboto', sans-serif;
    text-align: left;
    color: var(--text-clr);
}
select.plainInput { padding: .5rem 1rem; }

.noPaddingBlock { margin: 0 -3rem; }

.title { margin: 3rem 0; }

.separator {
    margin: 2rem 0 3rem;
    border-top: 1px solid var(--border-clr--light);
}

.table {
    width: 100%;
    border-collapse: collapse;
}
.table > thead > tr > th {
    padding: .7rem 1.5rem;
    background-color: #F3F5F5;
    font: 700 1.3rem/1.6rem 'Roboto', sans-serif;
    color: var(--text-clr);
    text-align: left;
    white-space: nowrap;
}
.table > tbody > tr > td {
    padding: .5rem 1.3rem;
    border: 1px solid var(--border-clr--light);
    font: 400 1.3rem/2.3rem 'Roboto', sans-serif;
}
.table > tbody > tr > td:has(.buttonAdd) {
    padding: 0;
    border: none;
    background: rgba(83, 166, 150, .2);
}
.table > tbody > tr > td:has(.plainInput) {
    padding: 0;
}

.del {
    display: block;
    width: 2rem;
    height: 2rem;
    margin: 0 auto;
    background: url('../../../../public/i/icon-del.svg') no-repeat center;
    background-size: 1.6rem;
    cursor: pointer;
}

.buttonAdd {
    display: flex;
    width: 100%;
    height: 4rem;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    color: var(--primary-clr);
    font-weight: bold;
}
.buttonAdd:before {
    content: '';
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    background: url('../../../../public/i/icon-add-small.svg') no-repeat center / contain;
}

.searchWrap {
    position: relative;
    height: 3.3rem;
}
.searchOpen, .searchClose {
    width: 2rem;
    height: 100%;
    margin: 0 auto;
    background: url('../../../../public/i/icon-search.svg') no-repeat center;
}
.searchClose {
    background-image: url('../../../../public/i/icon-close.svg');
}
.searchInput {
    position: absolute;
    top: 0;
    left: calc(100% + 1px);
    z-index: 1;
}
.resultsWrap {
    position: absolute;
    width: max-content;
    min-width: 32rem;
    max-height: 22rem;
    top: calc(100% + 1px);
    left: calc(100% + 1px);
    padding: 1rem 0;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow-y: auto;
    filter: drop-shadow(0 10px 20px rgba(0, 0, 0, .15));
}
.results {
    width: 100%;
    text-align: left;
}
.results th {
    padding-bottom: .6rem;
    font-weight: bold;
}
.results tbody tr:not(.border) {
    cursor: pointer;
}
.results tbody tr:not(.border):hover {
    background-color: #F6F6F6;
}
.results tbody tr.border td { padding: 0; }
.results tbody tr.border td:not(.empty) div {
    margin: .6rem 0;
    height: 1px;
    background-color: #D9D9D9;
}

.results tbody td {
    padding: .5rem 0;
    font: 400 1.2rem/1.4rem 'Roboto', sans-serif;
    color: var(--text-clr);
    letter-spacing: .03em;
}
.results tr:not(.border) td:not(.empty):nth-child(n+3),
.results tr:not(.border) th:not(.empty):nth-child(n+3) {
    padding-left: .5rem;
}
.results td:nth-child(2) {
    color: var(--text-clr--semiopacity);
}
.results td.empty {
    width: .9rem;
}
.results td.empty:first-child, .results td.empty:last-child {
    width: 1.6rem;
}
.results td.empty:nth-child(3) {
    border-right: 1px solid #D9D9D9;
}