.localization {
    margin-top: auto;
    height: 7.5rem;
    width: 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-top: 1px solid #9E9E9E;
    transition: all 0.1s linear 0.01s;
}

.localization:hover {
    background: var(--submenu-bg);
}

.localization.visible {
    background: var(--submenu-bg);
}

.localization.visible > .localizationLanguages {
    display: flex;
}

.localizationLanguage {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    color: var(--text-clr--light);
    width: 100%;
    cursor: pointer;
    text-transform: uppercase;
    font: 700 1.3rem/1.8rem Roboto, sans-serif;
}

.localizationLanguageIcon {
    width: 4rem;
    height: 4rem;
    mask-image: url('./i/icon-localization.svg');
    -webkit-mask-image: url('./i/icon-localization.svg');
    background-color: var(--text-clr--light);
}

.localization.visible > .localizationLanguage > .localizationLanguageIcon {
    background-color: var(--secondary-clr);
}

.localization.visible > .localizationLanguage {
    color: var(--secondary-clr);
}

.localizationLanguages {
    display: none;
    align-items: center;
    gap: 3.5rem;
    padding: 2rem 3.5rem;
    position: absolute;
    top: -1px;
    left: 100%;
    z-index: 9999;
    width: 27.6rem;
    height: 7.5rem;
    border-top: 1px solid #9E9E9E;
    background: var(--submenu-bg);
    font: 700 1.3rem/1.8rem Roboto, sans-serif;
    color: var(--text-clr--light);
}

.localizationLanguages > a:hover {
    color: #ffffff;
}

.currentLanguage {
    color: #ffffff;
    display: inline-flex;
    align-items: center;
}

.currentLanguage:before {
    content: '';
    margin-right: 1rem;
    width: 0.7rem;
    height: 0.5rem;
    background: url('./i/icon-checkmark.svg') no-repeat center / contain;
}
