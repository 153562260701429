.snackbar {
    position: absolute;
    left: var(--page-margin-h);
    bottom: 2rem;
    z-index: 9999;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    border-radius: 0.4rem;
    overflow: hidden;
    animation: fadeIn 0.5s, fadeOut 0.5s 4.5s;
}

.snackbar > .icon {
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.snackbar.info > .icon {
    background: #2FA9B0 url("i/info-icon.svg") no-repeat center;
}
.snackbar.success > .icon {
    background: #2FB075 url("i/success-icon.svg") no-repeat center;
}
.snackbar.warning > .icon {
    background: #E79925 url("i/warning-icon.svg") no-repeat center;
}
.snackbar.danger > .icon {
    background: #ED7C59 url("i/danger-icon.svg") no-repeat center;
}

.snackbar > .messageWrapper {
    padding: 1.6rem;
}

.snackbar > .messageWrapper > h1 {
    font: 600 1.4rem/2rem 'Roboto', sans-serif;
    margin-bottom: 0.4rem;
}

.snackbar > .messageWrapper > span {
    font-size: 1.4rem;
    line-height: 2rem;
}

.snackbar > .messageWrapper > button {
    margin-top: 0.8rem;
}

.snackbar > .close {
    width: 2.4rem;
    height: 2.4rem;
    margin: 1.6rem;
    cursor: pointer;
    background: url("i/close-icon.svg") no-repeat center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        bottom: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}