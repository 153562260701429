.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
}

.loader:after {
    content: '';
    width: 5rem;
    height: 5rem;
    background: url('../../../public/i/loader.svg') no-repeat center / contain;
}