.wrap {
    display: flex;
    margin: -1rem -3rem 0;
    padding: 1.7rem 1.5rem;
    gap: 1.5rem 2rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    background: #FAFAFA;
}
.wrap.insideSubmenu {
    margin: 0;
    padding: 0;
    background: unset;
}
.wrap.insideCardDiary {
    margin: 0;
    grid-column: 1 / span 2;
    border-top: 1px solid var(--border-clr--light);
}

.wrap:not(.insideSubmenu):not(.insideCardDiary) + * { margin-top: 3rem; }

.toggle {
    min-width: 8.6rem;
    padding: .5rem 1.5rem;
    border: 1px solid var(--border-clr);
    border-radius: 1.3rem;
    font: 400 1.1rem/1.5rem 'Roboto', sans-serif;
    color: var(--text-clr--bright);
}

.toggle.filled {
    background: #fff;
    border-color: var(--secondary-clr);
    color: var(--secondary-clr);
}

.toggle.active {
    background: var(--secondary-clr);
    border-color: var(--secondary-clr);
    color: #fff;
}