.panel {
    margin: 0 var(--page-margin-h);
    padding: 3rem;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    position: relative;
}

.panel.loading {
    position: relative;
    background: url('../../../../public/i/loader.svg') no-repeat center;
    background-size: 5rem;
}

.panel.loading:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
}

.row {
    display: grid;
    grid-template-columns: repeat(2, minmax(12rem, 30rem));
    column-gap: 5rem;
    overflow: hidden;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.inputWrap > input,
.inputWrap > select {
    border-color: var(--primary-clr);
}

.inlineInputs {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.inlineInputs + .inputWrap {
    margin-top: 1.5rem;
}

.inlineInputWrap {
    max-width: 12rem;
    width: 100%;
}

.inlineInputWrap + .inlineInputWrap {
    margin-top: 0;
}

.actions {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
}