.button {
    min-width: 10rem;
    padding: .7rem 1rem;
    border: 1px solid var(--primary-clr);
    border-radius: 1.5rem;
    background: transparent;
    font: 700 1.2rem/1.4rem 'Roboto', sans-serif;
    color: var(--primary-clr);
    text-align: center;
    transition: background-color ease-in-out .3s;
}

.button:hover {
    background-color: var(--primary-bg--active);
}

.submit {
    border-color: transparent;
    background-color: var(--primary-clr);
    color: #fff;
}

.submit:hover {
    background-color: var(--primary-clr--active);
}

.reset {
    border-color: var(--decline-clr);
    background-color: #fff;
    color: var(--decline-clr);
}

.reset:hover {
    background-color: var(--decline-bg--active);
}