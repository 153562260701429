.link {
    padding: .9rem .9rem .9rem 2.8rem;
    font: 700 1.5rem/1.8rem 'Roboto', sans-serif;
    color: #FFFFFF;
}

.link + .link {
    margin-top: 1rem;
}

.link:hover, .linkActive {
    background: var(--submenu-bg--active);
}