.patientsGrid {
    display: grid;
    grid-gap: 2rem 4rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.tabs {
    margin: -1rem -3rem 3rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1.7rem;
    border-bottom: 1px solid var(--border-clr);
    border-radius: .4rem .4rem 0 0;
    background: #FAFAFA;
}

.tabs > span {
    margin-bottom: -1px;
    padding: .5rem 2.3rem;
    border: 1px solid transparent;
    border-radius: .4rem .4rem 0 0;
    font: 400 1.7rem/2rem 'Roboto', sans-serif;
    color: var(--text-clr--light);
    cursor: pointer;
}

.tabs > span:first-child {
    border-left: none;
    border-radius: 0 .4rem 0 0;
}

.tabs > span.sel {
    border-color: var(--border-clr);
    border-bottom-color: #fff;
    background: #fff;
    color: var(--primary-clr);
}

.charts {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.chart {
    width: 100%;
    height: 100%;
}
