.sortTable {
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
}

.sortTable > thead > tr > th,
.sortTable > thead > tr > td,
.sortTable > tbody > tr > th,
.sortTable > tbody > tr > td,
.sortTable > tfoot > tr > th,
.sortTable > tfoot > tr > td {
    border: 1px solid var(--border-clr--light);
}

.sortTable > thead > tr > th {
    padding: .7rem 1.5rem;
    border-color: #F3F5F5;
    background-color: #F3F5F5;

    font: 700 1.3rem/1.6rem 'Roboto', sans-serif;
    color: var(--text-clr);
    text-align: left;
    white-space: nowrap;
    vertical-align: top;
}

.sortTable > tbody > tr > td {
    padding: .7rem 1.5rem;

    font: 400 1.3rem/1.6rem 'Roboto', sans-serif;
    color: var(--text-clr);
    text-align: left;
    vertical-align: top;
}

.sortTable > tbody > tr.isEven {
    background-color: #F3F5F5;
}

.sortTable > tbody > tr:hover {
    background-color: #D3D3D3;
}

.sortTable > tbody > tr:focus-within {
    background-color: var(--primary-clr);
}

.sortTable > tbody > tr:focus-within > td {
    color: #fff;
}
