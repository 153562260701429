.wrap {
    grid-area: app_user;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: stretch;
    margin-right: var(--page-margin-h);
    gap: 4rem;
}

.card {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    display: flex;
    width: 100%;
    white-space: nowrap;
    font: 300 1.6rem/1.9rem 'Roboto', sans-serif;
}

.card:not(:last-of-type) {
    padding-right: 4rem;
    border-right: 1px solid var(--border-clr);
}

.card b {
    font-weight: 400;
}

.cardLogout {
    cursor: pointer;
}

.icon {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    flex-shrink: 0;
}
.icon:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    mask: no-repeat center;
}

.iconUser { background-color: var(--secondary-clr--active); }
.iconUser:after {
    mask-image: url('./i/icon-user.svg');
    background-color: #FFFFFF;
}

.iconLogout { background-color: var(--primary-bg--light); }
.iconLogout:after {
    mask-image: url('./i/icon-logout.svg');
    background-color: var(--primary-clr);
}