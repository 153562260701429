.link {
    padding: .9rem .9rem .9rem 2.8rem;
    font: 700 1.5rem/1.8rem 'Roboto', sans-serif;
    color: #FFFFFF;
    cursor: pointer;
}

.link + .link {
    margin-top: 1rem;
}

.link:hover, .linkActive {
    background: var(--submenu-bg--active);
}

.filters {
    margin-top: 8rem;
    padding: 3rem 2rem;
    border-top: 1px solid #9E9E9E;
}
.filtersTitle {
    color: #FFFFFF;
    font: 700 1.5rem/1.8rem 'Roboto', sans-serif;
    margin-bottom: 2.4rem;
}