.wrap {
    display: flex;
    flex-flow: column;
    width: 28rem;
    height: 38rem;
    border: 1px solid var(--border-clr);
    border-radius: .4rem;
}

.files {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
}

.slide {
    flex-grow: 0;
    flex-shrink: 0;
    width: 4rem;
    background: url('slide.svg') no-repeat center;
}
.slide:hover {
    background-color: var(--primary-bg--light);
}
.slide:disabled {
    opacity: .5;
    filter: grayscale(1);
}

.next {
    transform: rotate(180deg);
}

.file {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;

    background: no-repeat center / contain;

    font: 400 1rem/1.2rem 'Roboto', sans-serif;
    color: var(--text-clr);
}

.file span {
    padding: .3rem;
    background: rgba(255, 255, 255, .7);
}

.controls {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 4rem;
    border-top: 1px solid #D8D8D8;
}

.controls > *:not(:last-child) {
    border-right: 1px solid #D8D8D8;
}

.control {
    flex-grow: 0;
    flex-shrink: 0;
    width: 5rem;
    background: no-repeat center;
}
.control:hover {
    background-color: var(--primary-bg--light);
}
.control:disabled {
    opacity: .5;
    filter: grayscale(1);
}

.pages {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.add {
    background-image: url('add.svg');
}
.del {
    background-image: url('del.svg');
}
.manual {
    background-image: url('manual.svg');
}
.ai {
    background-image: url('ai.svg');
}

.pdf {
    background-image: url('pdf.svg');
    background-size: contain;
}

.upload {
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
}