.page { position: relative; }

.pageName {
    grid-area: app_name;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: var(--page-margin-h);
    font: 700 2.5rem/2.9rem 'Roboto', sans-serif;
    color: var(--text-clr);
}

.pageName.homePage { color: var(--primary-clr); }

.pageCont {
    grid-area: app_cont;
    position: relative;
}