.card {
    padding: 1.3rem;
    border: 1px solid transparent;
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 2px 2px 9px rgba(0, 0, 0, .2);
    cursor: pointer;
}

.selected {
    background-color: var(--primary-clr);
    color: #FFFFFF;
}

.top {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.icon {
    width: 4rem;
    height: 4rem;
    margin-right: 1.5rem;
    border-radius: 50%;
    background: var(--secondary-clr--active) url('./user.svg') no-repeat center;
}

.name {
    display: flex;
    flex-flow: column;
}