@font-face {
  /* feeling smiles: 'T' - Terrible, 'B' - Bad, 'N' - Normal, 'G' - Good, 'E' - Excellent */
  /* feeling note icon - 'C' */
  font-family: 'Glyphter';
  src: url('../public/fonts/Glyphter.eot');
  src: url('../public/fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Glyphter.woff') format('woff'),
    url('../public/fonts/Glyphter.ttf') format('truetype'),
    url('../public/fonts/Glyphter.svg#Glyphter') format('svg');
  font-weight: normal;
  font-style: normal;
}

:root {
  --header-height: 9rem;
  --page-margin-h: 3rem;

  --main-title: 700 1.7rem/2rem 'Roboto', sans-serif;

  --menu-bg: rgb(98, 101, 101);
  --menu-bg--active: rgb(76, 82, 82);
  --submenu-bg: rgb(76, 82, 82);
  --submenu-bg--active: rgb(43, 51, 51);
  --text-clr: rgb(76, 82, 82);
  --text-clr--semiopacity: rgba(76, 82, 82, .5);
  --text-clr--light: rgb(140, 140, 140);
  --text-clr--bright: rgb(185, 185, 185);

  --border-clr: rgb(185, 185, 185);
  --border-clr--light: rgb(211, 211, 211);

  --primary-clr: rgb(83, 166, 150);
  --primary-clr--active: rgb(64, 143, 128);
  --primary-clr--bright: rgb(34, 160, 143);
  --primary-clr--translucent: rgba(83, 166, 150, .5);
  --primary-bg--light: rgb(243, 245, 245);
  --primary-bg--active: rgb(245, 253, 251);

  --secondary-clr: rgb(180, 196, 20);
  --secondary-clr--active: rgb(202, 224, 15);

  --decline-clr: rgb(211, 61, 61);
  --decline-bg--active: rgb(255, 245, 245);
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  text-decoration: none;
  background: none;
  border-collapse: collapse;
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 10px;
}

body {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  min-height: 100%;
  font: 400 1.3rem/1.7rem Roboto, sans-serif;
  color: var(--text-clr);
  background: #EEEEEE;
}

a, button {
  cursor: pointer;
  transition: all 0.1s linear 0.01s;
}
button:disabled {
  cursor: not-allowed !important;
}

#root {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  position: relative;
}