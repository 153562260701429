.row {
    display: grid;
    grid-column: 1/3;
    grid-template-columns: 3fr 1fr;
    align-items: flex-start;
    gap: 3rem;
    position: relative;
}

.searchBox {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.searchBox > .smallText {
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
    font: 400 1rem/1.2rem Roboto, sans-serif;
    color: var(--text-clr);
}