.self {
    width: 8rem;
    border-left: 1px solid var(--border-clr--light);
    background: no-repeat center;
}

.self:disabled {
    opacity: .5;
    cursor: not-allowed;
}

.add {
    background-image: url('./i/add.svg');
}

.delete {
    background-image: url('./i/delete.svg');
}