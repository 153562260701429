.panel {
    margin: 0 var(--page-margin-h);
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    position: relative;
}

.plainPanel {
    margin: 0;
    border: 1px solid var(--border-clr);
    box-shadow: none;
}

.transparentHeadPanel {
    border-radius: unset;
    background: unset;
    box-shadow: unset;
}

.formPanel {
    margin: 0
}

.panel + .panel {
    margin-top: 4rem;
}

.transparentHeadPanel + .transparentHeadPanel {
    margin-top: 2rem;
}

.head {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 6.3rem;
    border-bottom: 1px solid var(--border-clr);
}
.head.loading {
    background: url('../../../public/i/loader.svg') no-repeat center / contain;
}

.transparentHeadPanel .head {
    border-bottom: unset;
}

.formPanel .head {
    height: auto;
    border-bottom: none;
}

.title {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 3.1rem;
    font: var(--main-title);
    color: var(--text-clr);
}

.transparentHeadPanel .title {
    margin-left: 1.6rem;
    font-size: 2rem;
    line-height: 2.3rem;
}

.formPanel .title {
    margin: 3rem 5.5rem 1rem;
    text-align: center;
}

.actions {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;

    height: 100%;
}

.actionsAsList {
    align-items: center;
}

.actionsAsList > * {
    margin-right: 3rem;
}

.actionsAsList > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.actionsAsList > div > span {
    width: 3rem;
    text-align: center;
}

.body {
    padding: 1rem 3rem;
}

.transparentHeadPanel .body {
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .15);
}

.formPanel .body {
    padding: 4rem 5.5rem;
}