.wrap {
    position: relative;
    padding: 2rem 0 2rem 2.4rem;
}

.wrap + .wrap {
    margin-top: 3rem;
}

.label {
    left: 0;
    top: 50%;
    z-index: 3;
    position: absolute;
    font: 700 1.2rem/2.4rem 'Roboto', sans-serif;
    color: var(--primary-clr--bright);
    white-space: nowrap;
    writing-mode: tb-rl;
    transform: translate(0, -50%) rotate(-180deg);
}

.tableWrap {
    position: relative;
    overflow: auto;
    white-space: nowrap;
}

.table {
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    width: 100%;
    margin-bottom: 2rem;
    table-layout: fixed;

    font: 400 1rem/1rem 'Roboto', sans-serif;
    color: var(--text-clr);
}

.table > * > tr > th {
    overflow: hidden;
    left: 0;
    width: 7rem;
    position: sticky;
    z-index: 2;
    padding: .5rem 1rem .5rem 0;
    border-right: 1px solid var(--primary-clr);
    border-bottom: 1px solid #fff;
    background-color: white;
    text-align: right;
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
}

.table > tbody > tr > td {
    overflow: hidden;
    padding: .5rem 0;
    width: 4rem;
    border-bottom: 1px solid var(--border-clr);
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table > * > tr > td:not(:last-child):nth-child(3n + 4) {
    border-right: 1px solid var(--primary-clr);
}

.table > tbody > tr:last-child > th {
    border-bottom: 1px solid var(--border-clr);
}

.table > tfoot > tr > td {
    position: relative;
    width: 4rem;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table > tfoot > tr > td > div {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.table > tfoot > tr > td > div:before {
    content: '';
    margin-bottom: .8rem;
    height: .6rem;
    border-left: 1px solid var(--border-clr);
}

.table > tfoot > tr > td > span {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: .5rem;
    color: var(--primary-clr);
    transform: translate(-50%, 100%);
}

.table > tbody > tr > td:last-child, .table > tfoot > tr > td:last-child {
    width: auto;
    border: none;
}