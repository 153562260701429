.menu {
    grid-area: app_subm;
    display: flex;
    flex-flow: column;
    background: var(--submenu-bg);
    width: 27.6rem;
    padding-bottom: 10rem;
}

.menu > hr {
    margin: 1rem 0;
    border-top: 1px solid #747A7A;
}

.logo {
    height: var(--header-height);
    background: var(--menu-bg) url('logo-desc.svg') no-repeat left center;
}

.title {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 7.1rem;
    padding-left: 2rem;
    font: 700 1.7rem/2rem 'Roboto', sans-serif;
    color: var(--secondary-clr--active);
    text-transform: uppercase;
}