.panel {
    margin: 0 var(--page-margin-h);
    padding: 5rem 3rem;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    position: relative;
    display: flex;
    justify-content: center;
}

.panel.loading {
    position: relative;
    background: url('../../../../public/i/loader.svg') no-repeat center;
    background-size: 5rem;
}

.panel.loading:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
}

.form {
    max-width: 30rem;
    width: 100%;
}

.inputWrap > input {
    border-color: var(--primary-clr);
}

.inputWrap > input.error {
    border-color: var(--decline-clr);
}

.actions {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
}