.title {
    margin-right: .5rem;
    font: 500 1.6rem/2rem 'Roboto', sans-serif;
    color: var(--text-clr);
}

.scaleWrap {
    position: relative;
    min-width: 50rem;
    max-width: 62.4rem;
    height: 1.5rem;
    margin: 2.2rem 0;
    background: linear-gradient(90deg, rgb(20, 174, 92) 0%, rgb(84, 184, 82) 25.9%, rgb(248, 211, 58) 70.1%, rgb(255, 0, 0) 103.55%);
    border-radius: 3rem;
    box-shadow: 0 8px 20px rgba(175, 175, 175, .4);
}
.whiteLine {
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: rgb(255, 255, 255);
}
.whiteLine:nth-child(1) { left: 18.11%; }
.whiteLine:nth-child(2) { left: 39.26%; }
.whiteLine:nth-child(3) { left: 60.42%; }
.whiteLine:nth-child(4) { left: 81.57%; }

.risk {
    position: absolute;
    display: flex;
    top: 2.1rem;
    column-gap: .5rem;
    transform: translateX(-50%);
}
.risk.low {
    left: 9.05%;
    --risk-color: #16AF5D;
}
.risk.below_average {
    left: 28.69%;
    --risk-color: #8FC24B;
}
.risk.average {
    left: 49.85%;
    --risk-color: #F1CB00;
}
.risk.above_average {
    left: 71%;
    flex-direction: row-reverse;
    --risk-color: #FB9228;
}
.risk.high {
    left: 90.79%;
    flex-direction: row-reverse;
    --risk-color: #FF1C08;
}

.arrow {
    width: 1.4rem;
    height: 1.4rem;
    mask: url('arrow.svg') no-repeat center;
    background-color: var(--risk-color);
}

.text {
    font: 500 1.2rem/1.5rem 'Roboto', sans-serif;
    color: var(--risk-color);
}