.menu {
    grid-area: app_menu;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    width: 7.5rem;
    background: var(--menu-bg);
}

.menu > hr {
    margin-top: 4rem;
    border-top: 1px solid #747A7A;
}

.logo {
    height: var(--header-height);
    background: url('./i/logo.png') no-repeat center / 4rem 4.8rem;
    cursor: pointer;
}

.link {
    height: 7.5rem;
    width: 7.5rem;
    background: no-repeat center;
    cursor: pointer;
}
.link:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    mask: no-repeat center;
    background-color: var(--text-clr--bright);
}

.link:hover { background-color: var(--menu-bg--active); }

.iconHome:after { mask-image: url('./i/icon-home.svg'); }

.iconVisit:after { mask-image: url('./i/icon-visit.svg'); }

.iconDynamic:after { mask-image: url('./i/icon-dynamic.svg'); }

.iconDoctor:after { mask-image: url('./i/icon-doctor.svg'); }

.iconGear:after { mask-image: url('./i/icon-gear.svg'); }

.iconUser:after { mask-image: url('./i/icon-user.svg'); }

.linkActive { background-color: var(--menu-bg--active); }

.linkActive:after { background-color: var(--secondary-clr); }