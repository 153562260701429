.reportPage {
    flex-grow: 1;
    max-width: 59.5rem;
    width: 100%;
    padding-top: 2.5rem;
    margin: 0 auto;
    background: url('i/logo.svg') no-repeat top / contain;
}

.reportPage.loading {
    position: relative;
    background: url('../../../public/i/loader.svg') no-repeat center;
    background-size: 5rem;
}

.reportPage.loading:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
}

.header {
    display: flex;
    margin: 0 3.5rem;
    align-items: center;
    justify-content: space-around;
}
.logo {
    width: 6rem;
    height: 6rem;
    margin-right: 3rem;
    flex-shrink: 0;
    background: url('i/pressure.svg') no-repeat center;
}
.title {
    font: 500 1.6rem/2rem 'Roboto', sans-serif;
    color: #FFFFFF;
}

.report {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2.5rem;
    padding: 2rem 1.5rem 1.5rem;
    max-width: 100%;
    min-height: calc(100vh - 11rem);
    background: #FFFFFF;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
}

.report > h1 {
    margin-bottom: 1rem;
    font: 600 1.6rem/2.5rem 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: .01em;
    color: rgb(0, 113, 149)
}

.report > p {
    margin-bottom: 1.5rem;
    font: 600 1.4rem/2.0rem 'Roboto', sans-serif;
    color: #000000;
}

.separator {
    width: calc(100vw);
    max-width: 59.5rem;
    border-top: 1.3rem solid rgb(246, 246, 246);
}

.tableWrap {
    width: 100%;
    padding-top: 2rem;
    margin-bottom: 1.5rem;
    border-top: 1px solid rgba(135, 135, 135, .3);
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    border-bottom: 1px solid var(--border-clr--light);
}

.table > thead > tr > th {
    padding: .9rem 1rem;
    border-color: #F3F5F5;
    background-color: rgba(135, 135, 135, .15);
    font: 600 1.2rem/1.6rem 'Roboto', sans-serif;
    color: #000000;
    text-align: left;
    white-space: nowrap;
    vertical-align: top;
}

.table > tbody > tr > td {
    border-collapse: collapse;
    border: 1px solid var(--border-clr--light);
    padding: 0.6rem 1rem;
    font: 400 1.2rem/1.6rem 'Roboto', sans-serif;
    color: var(--text-clr);
    text-align: left;
    vertical-align: middle;
}

.table > tbody > tr > td.feeling {
    font: normal 1.5rem / 1.6rem 'Glyphter';
}

.table > tbody > tr > td.bgRed {
    background: #FFDEDE;
}

.charts {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

.chart {
    width: 100%;
    height: 100%;
}

.link {
    margin-top: 50px;
    margin-bottom: 20px;
    text-decoration: underline;
    color: var(--primary-clr);
    font: 400 1.6rem/1.6rem 'Poppins', sans-serif;
}