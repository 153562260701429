:root {
    --color-red: #FF2405;
    --color-orange: #FFA31A;
    --color-yellow: #FFD131;
    --color-green: #5DE558;
    --color-bright-green: #27C721;
}

.rangeWrapper {
    grid-column: span 2;
    position: relative;
}

.label {
    margin-bottom: .6rem;
    font: 400 1rem/1rem 'Roboto', sans-serif;
    text-transform: uppercase;
    color: var(--text-clr--bright);
}

.rangeLabels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font: 400 1rem/1.2rem 'Roboto', sans-serif;
    color: var(--text-clr--bright);
    margin-top: 0.5rem;
}

.range {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
}

input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    background: linear-gradient(to right, var(--color-red) 20%, var(--color-orange) 20%, var(--color-orange) 40%, var(--color-yellow) 40%, var(--color-yellow) 60%, var(--color-green) 60%, var(--color-green) 80%, var(--color-bright-green) 80%, var(--color-bright-green) 100%);
    border-radius: 99px;
    height: 0.6rem;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-moz-range-track {
    -moz-appearance: none;
    background: linear-gradient(to right, var(--color-red) 20%, var(--color-orange) 20%, var(--color-orange) 40%, var(--color-yellow) 40%, var(--color-yellow) 60%, var(--color-green) 60%, var(--color-green) 80%, var(--color-bright-green) 80%, var(--color-bright-green) 100%);
    border-radius: 99px;
    height: 0.6rem;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 2px solid #ffffff;
    border-radius: 50%;
    height: 2.2rem;
    width: 2.2rem;
    position: relative;
    bottom: 0.8rem;
    cursor: -webkit-grab;
    background-color: var(--color-bright-green);
    -webkit-transition: background-color ease 0.1s;
    transition: background-color ease 0.1s;
}

input[type=range]::-moz-range-thumb {
    -moz-appearance: none;
    border: 2px solid #ffffff;
    border-radius: 50%;
    height: 2.2rem;
    width: 2.2rem;
    position: relative;
    bottom: 0.8rem;
    cursor: -webkit-grab;
    background-color: var(--color-bright-green);
    -webkit-transition: background-color ease 0.1s;
    transition: background-color ease 0.1s;
}

.range.Terrible::-webkit-slider-thumb {
    background-color: var(--color-red);
}

.range.Bad::-webkit-slider-thumb {
    background-color: var(--color-orange);
}

.range.Normal::-webkit-slider-thumb {
    background-color: var(--color-yellow);
}

.range.Good::-webkit-slider-thumb {
    background-color: var(--color-green);
}

.range.Excellent::-moz-range-thumb {
    background-color: var(--color-bright-green);
}

input[type=range]::-webkit-slider-thumb:active {
    cursor: -webkit-grabbing;
}

input[type=range]::-moz-range-thumb:active {
    cursor: -moz-grabbing;
}