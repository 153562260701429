.panelDesc {
    margin: -1rem -3rem 0;
    font: 400 1.2rem/1.8rem Roboto, sans-serif;
    color: var(--text-clr);
    background: #FAFAFA;
    padding: 1rem 3rem;
}

.codeBlock {
    margin-top: 2.4rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    gap: 4.5rem;

    font: 400 1.2rem/1.8rem Roboto, sans-serif;
    color: var(--text-clr--light);
}

.codeBlock > *:not(.codeSeparator) {
    align-self: center;
}

.codeSeparator {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    gap: .7rem;
}

.codeSeparator:before, .codeSeparator:after {
    content: "";
    height: 100%;
    border-left: 1px solid var(--border-clr--light);
}

.codeCopyBlock {
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
}

.codeCopy {
    padding-right: 2.3rem;
    font: 400 1.6rem/1.8rem Roboto, sans-serif;
    color: var(--primary-clr);

    background: url("copy.svg") no-repeat right center / contain;
}

.codeQr {
    width: 12.6rem;
    height: 12.6rem;
    background: url("dummy-qr.png") no-repeat center / contain;
}

.shareLine {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 0;
}

.shareLine + .shareLine {
    border-top: 1px solid var(--border-clr--light);
}

.shareLine > div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
}

.requestSent {
    font: 400 1.4rem/1.8rem 'Roboto', sans-serif;
    color: var(--primary-clr);
}